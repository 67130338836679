import React from 'react';
import { graphql, Link } from "gatsby";
import { Container, Col, Row, Button } from "react-bootstrap";
import parse from "html-react-parser"
import $ from 'jquery';
import {
	FacebookShareButton,
	LinkedinShareButton,
	TwitterShareButton,
	WhatsappShareButton
} from "react-share";
import {
	EmailIcon,
	FacebookIcon,
	LinkedinIcon,
	TwitterIcon,
	WhatsappIcon
} from "react-share";
import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
import BannerIntro from "../components/BannerIntro/BannerIntro";
import OtherNewsArticles from "../components/NewsArticles/OtherNewsArticles";

const NewsDetailsTemplate = ({data}) => {

    const news = data.glstrapi.blog;

    const allNews = data.glstrapi.blogs.filter(newsValue => news?.id !== newsValue?.id);

    function gotohash(event) {
        var thishash = event;
        $('html, body').animate({
          scrollTop: $(thishash).offset().top - 70
        }, 1000);
    }

      // Social share

	const shareurl = typeof window !== 'undefined' ? window.location.href : ''
	
	// const [Shareicons,setShareicons] = React.useState(false);
	
	// const openShareicons = () => {
	// setShareicons(true);
	// if(Shareicons === true) {
	// 	setShareicons(false);
	// }
	// }

	const trackerShare = (event) => {    
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
		'event': 'Share - social icons',
		'formType': event + ' - ' +shareurl,
		'formId': 'Share',
		'formName': 'Share',
		'formLabel': 'Share'
	});    
	}
	// Social share

    return (
        <>
            <Header />

            <BannerIntro 
                heading={news?.blog_category?.category_name}
                title={news?.title}
                readTime={news?.read_time}
            />

            <Container className="news-detail-container">
                <div className="">
                    <Row>
                        <Col lg={11} className="order-lg-1 order-2">
                            <div className="news-details-img-wrapper">
                                <img src={news?.banner_image?.url} className="img-fluid" />
                            </div>
                        </Col>
                        <Col lg={1} className="order-lg-2 order-1">
                            <div className="d-lg-block d-flex news-details-img-social">
                                <div className="news-detail-social">
                                    <FacebookShareButton onClick={()=>trackerShare('FacebookShareButton')} url={shareurl} className="my-share-button facebook-share">
                                        <i className="icon icon-fb-round"></i>
                                    </FacebookShareButton>
                                </div>
                                <div className="news-detail-social">
                                    <TwitterShareButton onClick={()=>trackerShare('TwitterShareButton')} url={shareurl} className="my-share-button twitter-share">
                                        <i className="icon icon-twitter-round"></i>
                                    </TwitterShareButton>
                                </div>
                                <div className="news-detail-social">
                                    <LinkedinShareButton onClick={()=>trackerShare('LinkedinShareButton')} url={shareurl} className="my-share-button linkedin-share">
                                        <i className="icon icon-linkedin-round"></i>
                                    </LinkedinShareButton>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    {news?.above_content && <Row>
                        <Col lg={12} className="news-details-content">
                            <p>{parse(news?.above_content)}</p>
                        </Col>
                    </Row>}

                    {/* <section className="news-details-subscribe-block">
                        <Row className="d-flex align-items-center">
                            <Col lg={8}>
                                <div className="news-details-subscribe-heading">Would you like to know more?</div>
                                <div className="news-details-subscribe-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin egestas est</div>
                            </Col>
                            <Col lg={4}>
                                <Button variant="" className="btn-pink" onClick={() => gotohash("#NewsletterForm")}>Subscribe</Button>
                            </Col>
                        </Row>
                    </section> */}

                    <Row>
                        <Col lg={12}>
                            <div className="news-details-border-line-sm"></div>
                        </Col>
                    </Row>

                    {news?.below_content && <Row>
                        <Col lg={12} className="news-details-content">
                            <p>{parse(news?.below_content)}</p>
                        </Col>
                    </Row>}

                    <Row>
                        <Col lg={12}>
                            <div className="d-flex align-items-center news-details-share-wrapper">
                                <div className="news-details-share-text">Share this article:</div>
                                <div className="news-detail-social">
                                    <FacebookShareButton onClick={()=>trackerShare('FacebookShareButton')} url={shareurl} className="my-share-button facebook-share">
                                        <i className="icon icon-fb-round"></i>
                                    </FacebookShareButton>
                                </div>
                                <div className="news-detail-social">
                                    <TwitterShareButton onClick={()=>trackerShare('TwitterShareButton')} url={shareurl} className="my-share-button twitter-share">
                                        <i className="icon icon-twitter-round"></i>
                                    </TwitterShareButton>
                                </div>
                                <div className="news-detail-social">
                                    <LinkedinShareButton onClick={()=>trackerShare('LinkedinShareButton')} url={shareurl} className="my-share-button linkedin-share">
                                        <i className="icon icon-linkedin-round"></i>
                                    </LinkedinShareButton>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <OtherNewsArticles newsData={allNews} />
                </div>
            </Container>

            <Footer />
        </>
    )
}

export default NewsDetailsTemplate

export const pageQuery = graphql`
  query GetNewsPage($articleId: ID!) {
    glstrapi {
        blog(id: $articleId, publicationState: LIVE) {
            id
            slug
            title
            banner_image {
                url
            }
            imagetransforms
            above_content
            below_content
            blog_category {
                category_name
            }
            read_time
        }
        blogs {
            title
            id
            slug
        }
    }
  }
`